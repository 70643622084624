import React, { Component } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import FooterNew from 'components/footer/FooterNew';

import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


import setDocumentTitle from "utils/setDocumentTitle/setDocumentTitle";

import "./AboutNew.scss";
import { parseValue } from 'graphql';


export type ContributorType = {
  img: React.ReactNode,
  link: string,
  name: string,
  titles: string[],
}

const contributors:ContributorType[] = [
  {
    img: <StaticImage src="./InSongKim.jpg" alt="In Song Kim"/>,
    link:"http://web.mit.edu/insong/www/",
    name:"In Song Kim",
    titles:["Principal Investigator","Associate Professor at MIT"],
  },
  {
    img: <StaticImage src="./AdamRauh.jpg" alt="Adam Rauh"/>,
    link:"https://adamrauh.github.io/",
    name:"Adam Rauh",
    titles:["Researcher"],
  },
  {
    img: <StaticImage src="./AllisonChan.jpeg" alt="Allison Chan"/>,
    link:"",
    name:"Allison Chan",
    titles:["Illustrator"],
  },
  {
    img: <StaticImage src="./ArielMa.jpg" alt="Ariel Ma"/>,
    link:"https://www.linkedin.com/in/chiaman-ma/",
    name:"Ariel Ma",
    titles: ["UI/UX Design"],
  },
  {
    img: <StaticImage src="./ChristinaTai.jpg" alt="Christina Tai"/>,
    link:"",
    name:"Christina Tai",
    titles:["UX/UI Designer"],
  },
  {
    img: <StaticImage src="./ChrisLay.jpg" alt="Chris Lay"/>,
    link:"https://www.linkedin.com/in/chrisklay/",
    name:"Christopher Lay",
    titles:["Data Visualizations"],
  },
  {
    img: <StaticImage src="./EldenGriggs.jpeg" alt="Elden Griggs"/>,
    link:"",
    name:"Elden Griggs",
    titles:["Researcher"],
  },
  {
    img: <StaticImage src="./HarryLi.jpg" alt="Harry Li"/>,
    link:"https://harryxli.com/",
    name:"Harry Li",
    titles:["Frontend Technical Lead","Data Visualizations"],
  },
  {
    img: <StaticImage src="./JiwanJeong.jpg" alt="Jiwan Jeong"/>,
    link:"",
    name:"Jiwan Jeong",
    titles:["Postdoctoral Research + Fellow at MIT"],
  },
  {
    img: <StaticImage src="./SuyeolYun.jpg" alt=" Suyeol Yun"/>,
    link:"",
    name:"Suyeol Yun",
    titles:["Databases"],
  },
  {
    img: <StaticImage src="./YoungHwanJoo.jpg" alt="Young Hwan"/>,
    link:"https://rulyox.com",
    name:"YoungHwan Joo",
    titles:["Researcher"],
  },
];

const ALUMNI:{[key:string]:ContributorType[]} = {
  "Research": [
    {
      img: <StaticImage src="./RemiCura.jpg" alt="Remi Cura"/>,
      link:"http://remi.cura.info/",
      name:"Remi Cura",
      titles:["PhD, Project Management","Database, Machine Learning"],
    },
    {
      img: <StaticImage src="./DmitriyKunisky.jpg" alt="Dmitriy Kunisky"/>,
      link:"",
      name:"Dmitriy (Tim) Kunisky",
      titles:["Researcher"],
    },
    {
      img: <StaticImage src="./SoubhikBarai.jpg" alt="Soubhik Barai"/>,
      link:"",
      name:"Soubhik Barari",
      titles:[],
    },
    {
      img: <StaticImage src="./LexiWalker.jpg" alt="Lexi Walker"/>,
      link:"https://lexi-walker.github.io/",
      name:"Lexi Walker",
      titles:[],
    },
  ],
  "Data Visualization": [
    {
      img: <StaticImage src="./JeffreyShen.jpg" alt="Jeffrey Shen"/>,
      link:"https://jeffreyshen.com",
      name:"Jeffrey Shen",
      titles:["Data Visualizations"],
    },
    {
      img: <StaticImage src="./SydneyStrzempko.jpg" alt="Sydney Strzempko"/>,
      link:"https://stzuko.github.io/",
      name:"Sydney Strzempko",
      titles:["API, Visualizations,","Frontend & Backend"],
    },
  ],
  "Database": [
    {
      img: <StaticImage src="./JohnGallagher.jpg" alt="John Gallagher"/>,
      link:"http://www.johnjamesgallagher.com/",
      name:"John Gallagher",
      titles:["Project Management, Backend"],
    },
    {
      img: <StaticImage src="./AnujBheda.jpg" alt="Anuj Bheda"/>,
      link:"",
      name:"Anuj Bheda",
      titles:[],
    },
    {
      img: <StaticImage src="./NickSempere.jpg" alt="Nick Sempere"/>,
      link:"",
      name:"Nick Sempere",
      titles:[],
    },
  ],
  "UI/UX Design": [
    {
      img: <StaticImage src="./MaddieSchwartz.jpg" alt="Maddie Schwartz"/>,
      link:"https://maddoschwartz.github.io/",
      name:"Madeleine Schwartz",
      titles:["UI/UX Design"],
    },
  ],
}

export default class AboutNew extends Component {
  constructor(props) {
    super(props)

    setDocumentTitle("About")
  }

  render() {
    return (
      <div id="about-new">
        <div id="about-text">
          <Container>
            <br/><br/><br/>
            <h2>About LobbyView</h2>
            <br/>
            <p className="restricted-text">
              LobbyView started in __. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tristique et egestas quis ipsum suspendisse. Luctus venenatis lectus magna fringilla urna porttitor. Vitae suscipit tellus mauris a diam maecenas sed.
            </p>
            <br/><br/><br/>
          </Container>
        </div>
        
        <div id="team">
          <Container>
            <br/><br/><br/>
            <h2>Meet Our Team</h2>
            <br/>
            <Row>
              {contributors.map ((contributor, i) =>
                <Col xs={12} sm={6} md={4} lg={3} key={i}>
                  <div className="profile-new">
                    <div>{contributor.img}</div>
                    <br/>
                    <h4>{contributor.name}</h4>
                    {contributor.titles.map(t => 
                      <p key={t}>{t}</p>
                    )}
                  </div>
                  <br/><br/>
                </Col>
              )}
            </Row>
            <br/><br/><br/>
          </Container>
        </div>

        <div id="acknowledgements">
          <Container>
            <br/><br/>
            <h2>Acknowledgements</h2>
            <br/>
            <p className="restricted-text">
              LobbyView would not be possible without the countless contributions of those who have enriched this project. We’d like to thank the following individuals for their knowledge and invaluable insight.
            </p>
            <br/><br/><br/>
          </Container>
        </div>

        <div id="alumni">
          <Container>
            <Row>
              {Object.entries(ALUMNI).map(([key,values],i) =>
                <Col xs={12} sm={6} md={3} key={i}>
                  <p><b>{key}</b></p>
                  {values.map(v =>
                    <p key={v.name}>{v.name}</p>  
                  )}
                </Col>
              )}
            </Row>
            <br/><br/>
            <hr/>
          </Container>
        </div>

        <div id="join">
          <br/><br/><br/>
          <Container>
            <h2>Interested in joining our team?</h2>
            <br/>
            <p className="restricted-text">We're always looking for more talented individuals to join our LobbyView team. Whether you’re a designer, developer, researcher or just an individual passionate about the lobbying industry, we’d love to connect with you.</p>
            <br/>
            <a href="mailto:lobbydata@gmail.com"><Button>Work with us</Button></a>
          </Container>
          <br/><br/><br/>
        </div>

        {/* -------------------- FOOTER -------------------- */}
        <FooterNew/>

      </div>
    )
  }
}
